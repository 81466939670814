import {
  LegacyCard,
  ResourceList,
  ResourceItem,
  Text,
  Layout,
  Page,
  Card,
  EmptyState
} from '@shopify/polaris'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { DataUpdateContext } from '../../context/context'
import { backendRequest } from '../../utils/axiosInstance'
import { useNavigate } from 'react-router-dom'
import { BundleModal } from '../../components/shopify/BundleModal'

export const BomView = () => {
  const [boms, setBoms] = useState([])
  const [showBundleModal, setShowBundleModal] = useState(false)
  const { companyId, appBridge } = useContext(DataUpdateContext)
  const limit = 50
  const [offset, setOffset] = useState(0)
  const navigate = useNavigate()

  const handleModalChange = useCallback(() => setShowBundleModal(!showBundleModal), [showBundleModal])

  const emptyStateMarkup = !boms.length
    ? (
    <EmptyState
      heading="Create some bundles to get started"
      action={{ content: 'Create bundles' }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
      <p>
        You can contact support to upload bundles or create your own
      </p>
    </EmptyState>
      )
    : undefined

  const fetchBoms = useCallback(async (offset, limit) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/boms?company_id=${companyId}&limit=${limit}&skip=${offset}&populate=product`,
        data: {},
        appBridge
      })
      setBoms(response.data.boms)
    } catch (error) {
      console.error('Error fetching boms:', error)
    }
  }, [companyId, appBridge, setBoms])

  useEffect(() => {
    fetchBoms(offset, limit)
  }, [fetchBoms, offset, limit])

  const bomsPagination = {
    hasNext: true,
    onNext: () => {
      setOffset(offset + limit)
      fetchBoms(offset, limit)
    },
    hasPrevious: true,
    onPrevious: () => {
      setOffset(offset - limit)
      fetchBoms(offset, limit)
    }
  }

  const handleCreate = () => {
    navigate('/bundles/edit')
  }

  return (
    <Page
      title="Bundles"
      primaryAction={{
        content: 'Create Bundle',
        onAction: handleCreate
      }}
      divider
    >
      <Card>
        <Layout>
          <Layout.Section>
            <LegacyCard>
              <ResourceList
                emptyState={emptyStateMarkup}
                resourceName={{ singular: 'bundle', plural: 'bundles' }}
                items={boms}
                pagination={bomsPagination}
                renderItem={(item) => {
                  const bomId = item.bom_id
                  const bomName = item.bom_name
                  const productValariant = item.output_product.product_variant

                  return (
                    <ResourceItem
                      id={bomId}
                      onClick={() => {
                        navigate(`/bundles/edit?id=${bomId}`)
                      }}
                      accessibilityLabel={`View details for ${bomName}`}
                    >
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {bomName}
                      </Text>
                      <div>{productValariant}</div>
                    </ResourceItem>
                  )
                }}
              />
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <BundleModal
          active={showBundleModal}
          handleModalChange={handleModalChange}/>
      </Card>
    </Page>
  )
}
