import { Autocomplete, Icon, Page, Card, IndexTable, useIndexResourceState, BlockStack, Button, InlineGrid, Text, Toast, Frame } from '@shopify/polaris'
import { SearchIcon, PlusIcon } from '@shopify/polaris-icons'
import { useState, useCallback, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { backendRequest } from '../../utils/axiosInstance'
import { DataUpdateContext } from '../../context/context'
import { BundleModal } from '../../components/shopify/BundleModal'
import { EditableCell } from './EditableCell'
import { objectToId } from '../../utils'

export const BomCreationView = () => {
  const [searchParams] = useSearchParams()
  const [deselectedOptions, setDeselectedOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(deselectedOptions)
  const { companyId, appBridge } = useContext(DataUpdateContext)
  const [bom, setBom] = useState({ product: null, materials: [] })
  const [active, setActive] = useState(false)
  const [bomId, setBomId] = useState('')
  const [showBundleModal, setShowBundleModal] = useState(false)
  const [searchingForProduct, setSearchingForProduct] = useState(false)
  const [toastProps, setToastProps] = useState({ content: '', error: false })
  const [showToast, setShowToast] = useState(false)

  const resourceName = {
    singular: 'material',
    plural: 'materials'
  }

  const resourceIDResolver = (materials) => {
    return materials.materials_id
  }

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(bom.materials, { resourceIDResolver })

  const nameMockUp = active
    ? bom.output_product.product_name
    : null

  const handleSaveProduct = useCallback(async () => {
    try {
      await backendRequest({
        method: 'PUT',
        url: `/obius/boms/${bomId}`,
        data: bom,
        appBridge
      })
      setToastProps({ content: 'BOM saved successfully', error: false })
      setShowToast(true)
    } catch (error) {
      console.error('Error saving bom:', error)
      setToastProps({ content: 'Error saving BOM', error: true })
      setShowToast(true)
    }
  }, [bomId, bom, appBridge])

  const handleEditMaterial = useCallback((materialId, newValue) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.map(m =>
        m.material_id === materialId ? { ...m, quantity: newValue } : m
      )
    }))
    handleSaveProduct()
  }, [handleSaveProduct])

  const handleAddMaterial = useCallback((newMaterial) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: [...prevBom.materials, newMaterial]
    }))
    handleSaveProduct()
  }, [handleSaveProduct])

  const handleModalChange = useCallback(() => {
    setShowBundleModal(!showBundleModal)
    if (showBundleModal) {
      handleSaveProduct()
    }
  }, [showBundleModal, handleSaveProduct])

  const rowMarkup = active
    ? bom.materials.map(
      (
        material,
        index
      ) => (
        <IndexTable.Row
          id={material.material_id}
          key={material.material_id}
          selected={selectedResources.includes(material.material_id)}
          position={index}
        >
          <IndexTable.Cell>
            <img
              src={'https://picsum.photos/50?random=' + String(index)}
              alt={'product thumbnail' + material.product.product_name}
            />
          </IndexTable.Cell>
          <IndexTable.Cell>{material.product.product_name}</IndexTable.Cell>
          <EditableCell
            initialValue={material.quantity}
            onSave={(newValue) => handleEditMaterial(material.material_id, newValue)}
          />
        </IndexTable.Row>
      )
    )
    : null
  const updateText = useCallback(
    (value) => {
      setInputValue(value)

      if (value === '') {
        setOptions(deselectedOptions)
        return
      }

      const filterRegex = new RegExp(value, 'i')
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      )
      setOptions(resultOptions)
    },
    [deselectedOptions]
  )

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem)
        })
        return matchedOption && matchedOption.label
      })

      setSelectedOptions(selected)
      if (!bom.output_product) {
        const productId = selectedValue[1]
        setBom({ ...bom, bom_id: objectToId({ productId, companyId }), output_product: { product_name: selectedValue[0], product_id: selectedValue[1] } })
        setActive(true)
        setSearchingForProduct(false)
      }
      setInputValue(selectedValue[0] || '')
    },
    [options, setBom, bom, companyId]
  )

  const createDeselectedOptions = (products) => {
    return products.map((product) => ({
      value: product.product_id,
      label: `${product.product_name} - ${product.product_variant_name}`
    }))
  }

  const fetchExistingBomData = useCallback(async () => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/boms/${bomId}?populate=output_product,materials`,
        data: {},
        appBridge
      })
      console.info('Bom:', response.data)
      setBom(response.data)
      setActive(response.data.materials.length > 0)
    } catch (error) {
      console.error('Error fetching boms:', error)
    }
  }, [appBridge, bomId, setBom])

  useEffect(() => {
    setBomId(searchParams.get('id'))
    if (bomId) {
      fetchExistingBomData()
    } else {
      console.info('No bom id found')
    }
  }, [fetchExistingBomData, setBomId, searchParams, bomId])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await backendRequest({ method: 'GET', url: `/obius/products?company_id=${companyId}`, data: {}, appBridge })
        setDeselectedOptions(createDeselectedOptions(response.data.products))
        console.info('Products:', response.data.products)
      } catch (error) {
        console.log('Error fetching products:', error)
      }
    }

    fetchProducts()
  }, [setDeselectedOptions, appBridge, companyId])

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      onSelect={updateSelection}
      label='Products'
      value={inputValue}
      prefix={<Icon source={SearchIcon} tone='base' />}
      placeholder='Search'
      autoComplete='on'
    />
  )

  const handleCreateBundle = () => {
    setBom({ output_product: null, materials: [] })
    setActive(false)
    setSearchingForProduct(true)
  }

  const searchMockUp = searchingForProduct
    ? (
    <Card>
      <BlockStack gap='200'>
      <Autocomplete
          options={options}
          selected={selectedOptions}
          onSelect={updateSelection}
          textField={textField}
        />
      </BlockStack>
    </Card>
      )
    : null

  return (
    <Frame>
      <Page
        divider
        primaryAction={{ content: 'Create Bundle', disabled: false, onAction: () => handleCreateBundle() }}>
        <BlockStack gap='400'>
          {searchMockUp}
          <Card roundedAbove="sm">
            <BlockStack gap='200'>
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {nameMockUp}
                </Text>
                <Button
                  onClick={() => {
                    setShowBundleModal(true)
                  }}
                  accessibilityLabel="Add Material"
                  icon={PlusIcon}
                  disabled={!active}
                >
                  Add Material
                </Button>
              </InlineGrid>
              <IndexTable
                resourceName={resourceName}
                itemCount={bom.materials.length}
                selectedItemsCount={
                  allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                sortable={[false, false, false]}
                headings={[
                  { title: '' },
                  { title: 'Material' },
                  { title: 'Quantity', alignment: 'end' }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </BlockStack>
          </Card>
        </BlockStack>
        <BundleModal
          active={showBundleModal}
          products={deselectedOptions}
          setBom={setBom}
          bom={bom}
          handleModalChange={handleModalChange}
          onAddMaterial={handleAddMaterial}
        />
        {showToast && (
          <Toast
            content={toastProps.content}
            error={toastProps.error}
            onDismiss={() => setShowToast(false)}
          />
        )}
      </Page>
    </Frame>
  )
}
