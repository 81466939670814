import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import ShopifyLayout from './layouts/ShopifyLayout'
import ItemsView from './views/ItemsView'
import './App.css'
import { DataUpdateContext, DataUpdateProvider } from './context/context'
import ErrorPage from './components/common/ErrorPage'
import { PlanSelectionView } from './views/shopify/PlanSelectionView'
import { WelcomeView } from './views/shopify/WelcomeView'
import { ShopifySubcriptionReturn } from './views/shopify/ShopifySubcriptionReturn'
import { BomView } from './views/shopify/BomView'
import { BomCreationView } from './views/shopify/BomCreationView'

function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  const { isLogin, landingUrl } = useContext(DataUpdateContext)
  if (isLogin === null) {
    return <div />
  }

  if (isLogin === false) {
    return <ErrorPage errorCode='401' />
  }

  return (
    <Router>
      <Routes>
        <Route path='/shopify/subscription/return' element={<ShopifySubcriptionReturn />} />
        <Route path='*' element={<Navigate to={landingUrl} replace />} />
        <Route path='/welcome' element={<ShopifyLayout><WelcomeView /></ShopifyLayout>} />
        <Route path='/plan-selection' element={<ShopifyLayout><PlanSelectionView /></ShopifyLayout>} />
        <Route path='/bundles' element={<ShopifyLayout><BomView /></ShopifyLayout>} />
        <Route path='/bundles/edit' element={<ShopifyLayout><BomCreationView /></ShopifyLayout>} />
        <Route path='/items' element={<ShopifyLayout><ItemsView /></ShopifyLayout>} />
        <Route path='/401' element={<ErrorPage errorCode='401' />} />
        <Route path='/404' element={<ErrorPage errorCode='404' />} />
        <Route path='/500' element={<ErrorPage errorCode='500' />} />
      </Routes>
    </Router>
  )
}

export default App
