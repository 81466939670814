import { Modal, TextField, Form, FormLayout, Autocomplete, Icon, BlockStack, InlineError, Text } from '@shopify/polaris'
import { SearchIcon } from '@shopify/polaris-icons'
import { useCallback, useEffect, useState } from 'react'
import { objectToId } from '../../utils'
export const BundleModal = ({ active, handleModalChange, bom, setBom, products }) => {
  const [materialId, setMaterialId] = useState('')
  const [quantity, setQuantity] = useState('1')
  const [inputProduct, setInputProduct] = useState('')
  const [options, setOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])
  const [deselectedOptions, setDeselectedOptions] = useState([])

  const handleClose = useCallback(() => {
    handleModalChange()
  }, [handleModalChange])

  useEffect(() => {
    setDeselectedOptions(products)
  }, [products])

  function generateErrorMessage () {
    const materialError =
      !inputProduct || inputProduct === '' ? 'Please select an input product for the material' : ''
    const quantityError =
      !quantity || parseInt(quantity) <= 0 ? 'Selected quantity is invallid' : ''

    if (!materialError && !quantityError) {
      return ''
    }

    return (
      <span>
        <Text tone="critical" as="span">
          <p>
            {`${quantityError}`}
            {`${materialError}`}
          </p>
        </Text>
      </span>
    )
  }

  const updateText = useCallback(
    (value) => {
      setInputProduct(value)

      if (value === '') {
        setOptions(deselectedOptions)
        return
      }

      const filterRegex = new RegExp(value, 'i')
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      )
      setOptions(resultOptions)
    },
    [deselectedOptions]
  )

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem)
        })
        return matchedOption && matchedOption.label
      })
      setSelectedOptions(selected)
      setMaterialId(selected[0])
      setInputProduct(selectedValue[0] || '')
    },
    [options]
  )

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      onSelect={updateSelection}
      label='Products'
      value={inputProduct}
      prefix={<Icon source={SearchIcon} tone='base' />}
      placeholder='Search'
      autoComplete='on'
    />
  )

  const searchMockUp = (
    <BlockStack gap='200'>
    <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
      />
    </BlockStack>)

  const handleQuantityChange = useCallback((value) => {
    setQuantity(parseInt(value))
  }, [])

  const saveData = useCallback(async () => {
    bom.materials.push({ material_id: objectToId({ productID: materialId, bomId: bom.bom_id }), product_id: materialId, quantity, product: { product_name: inputProduct } })
    setBom(bom)
    handleClose()
  }, [bom, setBom, materialId, quantity, handleClose, inputProduct])

  const errorMessage = generateErrorMessage()
  return (
        <Modal
          open={active}
          onClose={handleClose}
          title="Material"
          primaryAction={{
            content: 'Save Bundle',
            onAction: saveData
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleClose
            }
          ]}
        >
          <Modal.Section>
          <Form onSubmit={saveData}>
            {searchMockUp}
            <FormLayout>
              <TextField
                onChange={handleQuantityChange}
                label="Quantity"
                value={quantity}
                align="right"
                type="number"
              />
            </FormLayout>
          </Form>
          <InlineError message={errorMessage}/>
          </Modal.Section>
        </Modal>
  )
}
