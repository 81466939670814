import React, { useState, useCallback } from 'react'
import { IndexTable, TextField, Button } from '@shopify/polaris'

export const EditableCell = ({ initialValue, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)

  const handleEdit = useCallback(() => setIsEditing(true), [])

  const handleChange = useCallback((newValue) => setValue(newValue), [])

  const handleBlur = useCallback(() => {
    setIsEditing(false)
    onSave(value)
  }, [onSave, value])

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      setIsEditing(false)
      onSave(value)
    }
  }, [onSave, value])

  if (isEditing) {
    return (
      <IndexTable.Cell>
        <TextField
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoComplete="off"
          autoFocus
        />
      </IndexTable.Cell>
    )
  }

  return (
    <IndexTable.Cell>
      <Button onClick={handleEdit} plain fullWidth>
        {value}
      </Button>
    </IndexTable.Cell>
  )
}
